<template lang="">
    <div>
      <div class="row">
        <div class="col-md-9 d-md-flex d-none">
          <div class="card dveb-white-card title-card">
            <div class="row my-auto">
              <div class="col-8">
                <Steps
                  :steps="5"
                  :activeStep="2"
                  :lastActive="true"
                  :stepNames="stepNames"
                  size="lg"
                />
              </div>
  
              <div style="flex: 1">
                <div class="d-flex px-2">
                  <router-link
                    :to="`/properties/find/detail/1`"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <b-button
                      variant="light"
                      class="mr-1"
                      :href="href"
                      @click="navigate"
                      ><i class="flaticon-home-1 text-warning p-0"></i
                    ></b-button>
                  </router-link>
                  
  
                  <router-link
                    :to="`declaration/detail`"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <b-button
                      variant="light"
                      class="mr-1"
                      :href="href"
                      @click="navigate"
                      ><i class="flaticon-eye text-success p-0 "></i
                    ></b-button>
                  </router-link>
                </div>
              </div>
              <div class="row flex-column justfiy-content-center" style="flex: 1">
                <b-button variant="outline-light" class="mx-auto"> 1 </b-button>
                <span
                  style="font-size: 0.7rem; font-weight: 300"
                  class="text-align-center mx-auto mt-0"
                  >İlgili Kişiler</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <Card class="dveb-grayheader-card aside-card">
            <template #header>
              <div class="badges-area">
                <div class="badges">
                  <i
                    class="badge"
                    v-for="(badge, key) in badges"
                    :class="[badgesEnum[key]]"
                  >
                  </i>
                </div>
                <span>TEST BEYAN 05.07.2023</span>
              </div>
            </template>
            <div class="row">
              <div class="col p-0">
                <b-button variant="outline-danger" style="width: 100%">
                  <i class="flaticon2-back"></i>
                  Geri
                </b-button>
              </div>
              <div class="col p-0 ml-1">
                <b-button variant="outline-primary" style="width: 100%">
                  İleri
                  <i class="flaticon2-next"></i>
                </b-button>
              </div>
              <div class="col p-0 ml-1">
                <b-button
                  variant="outline-light"
                  style="width: 100%"
                  v-b-toggle.sidebar-right
                >
                  <i class="flaticon-edit"></i>
                  <span style="font-size: 0.9rem !important">Notlar</span>
                </b-button>
              </div>
            </div>
            <div class="row">
              <b-dropdown variant="light" class="w-100 mt-2 varlik-dropdown">
                <template #button-content> Varlık İşlemleri </template>
                <b-dropdown-item class="w-100"> Dondur</b-dropdown-item>
                <b-dropdown-item class="w-100 d-flex"> Sil </b-dropdown-item>
              </b-dropdown>
            </div>
            
            <div class="row mt-4 d-flex d-md-none">
              <div class="col p-0 ml-1">
                <b-button
                  variant="outline-light"
                  style="width: 100%"
                  v-b-toggle.sidebar-left
                >
                  <i class="flaticon-edit"></i>
                  <span style="font-size: 0.9rem !important">Varlıklar</span>
                </b-button>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="card dveb-white-card tab-card">
            <b-tabs v-model="tabActive" content-class="mt-3">
              <b-tab title="Devir"><Devir /></b-tab>
              <b-tab title="Taşınmaz"><Tasinmaz /></b-tab>
              <b-tab title="Dosyalar"><Dosyalar /></b-tab>
            </b-tabs>
          </div>
        </div>
        <div class="col-md-3 d-md-flex d-none">
          <div class="card dveb-gray-card aside-gray-card">
            <div class="card-header">Re-Pie Portföy Yönetimi A.Ş.</div>
            <div class="bg-dveb-light d-flex justify-content-start flex-column align-items-start px-2 mt-4">
              <p class="text-bold">Randevu Tarihi: <span >08.06.2023 13:44</span></p>
              <p class="text-bold">Devir Tarihi: <span >08.06.2023 13:44</span></p>
            </div>
            <div class="mt-4">
              <MapView class="map" />
            </div>
            <div class="d-inline-flex mt-4">
              <div class="mx-auto d-inline-flex flex-column">
               
                <p class="text-bold mt-4">Hatay, ANTAKYA</p>
                <p>KUVA-İ MİLLİYE MAHALLESİ Balıkesir, KARESİ İlçesi, KUVA-İ MİLLİYE MAHALLESİ, . . Cadde/Sokak’ında bulunan, ada, parselde</p>
              </div>
            </div>
            
            
              
          </div>
        </div>
        <div class="notes-sidebar">
          <b-sidebar id="sidebar-right" right>
            <div
              class="card dveb-gray-card aside-gray-card"
              style="overflow-y: auto"
            >
              <div class="card-header">
                0 NOT
                <b-button variant="light ml-4" @click="noteModalShow = true"
                  >NOT EKLE
                </b-button>
              </div>
              <div
                class="card mt-4 dveb-card-white p-4"
                v-for="(note, key) in notes"
                :key="key"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4>{{ note.writer }}</h4>
                  <span class="text-bold">{{ note.date }}</span>
                  <p class="mt-4">{{ note.note }}</p>
                  <hr />
                  <div class="row">
                    <b-button
                      variant="danger"
                      class="ml-auto mx-4"
                      @click="openDeleteHandler(key)"
                    >
                      Sil
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-sidebar>
          <b-sidebar id="sidebar-left" left>
            <div class="card dveb-gray-card aside-gray-card">
              <div class="card-header">Re-Pie Portföy Yönetimi A.Ş.</div>
              <div class="mt-4">
                <MapView class="map" />
              </div>
              <div class="d-inline-flex mt-4">
                <div class="mx-auto d-inline-flex flex-column">
                  <b-button
                    v-b-tooltip.hover
                    title="Varlık Sahibinin Aynı İldeki Varlıkları"
                    variant="light"
                  >
                    <i class="flaticon-user"></i>
                    <span class="badge badge-secondary text-primary ml-4">1</span>
                  </b-button>
                  <p class="text-bold mt-4">Hatay, ANTAKYA</p>
                </div>
              </div>
              <div class="bg-dveb-light mt-4">
                <p class="text-bold">1 Tapu</p>
              </div>
              <div class="bg-dveb-light mt-4">
                <button class="btn btn-secondary btn-sm mr-4">
                  Tapuları Listele
                </button>
                <button class="btn btn-xs">
                  <i class="flaticon2-trash text-danger"></i>
                  Sil
                </button>
              </div>
            </div>
          </b-sidebar>
        </div>
      </div>
      <b-modal v-model="noteModalShow" id="note-modal">
        <template #modal-header>
          <h4><i class="flaticon2-plus mr-4"></i> Not Ekle</h4>
        </template>
        <div class="form-group">
          <label for="note">Not</label>
          <textarea
            name=""
            v-model="newNote"
            id="note"
            rows="4"
            class="form-control"
          ></textarea>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="noteModalShow = false">
              Vazgeç
            </b-button>
            <b-button variant="success" @click="addNewNote" class="ml-4">
              Kaydet
            </b-button>
          </div>
        </template>
      </b-modal>
      <DeleteOperation />
    </div>
  </template>
  <script>
  export default {
    name: "declarationDetail",
    components: {
      Steps: () => import("@/components/global/StepBar"),
      MapView: () => import("@/components/dashboard/MapView"),
  
      Dosyalar: () => import("@/components/transfer/salesDetail/Dosyalar"),
      Tasinmaz: () => import("@/components/transfer/salesDetail/Tasinmaz"),
      Devir: () => import("@/components/transfer/salesDetail/Devir"),

    },
    data() {
      return {
        noteModalShow: false,
        notes: [
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "ASUMAN KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "r KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "a KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "x KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
          {
            writer: "z KOCABAŞ",
            date: "29-05-2023 14:32",
            note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
          },
        ],
        stepNames : ["H:RY","O:GB","Y:RY","İ:RY","D:GB"],
        newNote: "",
        badges: [1, 2, 3],
        badgesEnum: ["green", "yellow", "red"],
        tabNames: [{ name: "Varlık", component: <EmlakBeyan /> }],
        albumEdit: false,
        tabActive: 0,
      };
    },
    methods: {
      addNewNote() {
        let newNote = {
          writer: "Burak Kaşıkçı",
          note: this.newNote,
          date:
            new Date().toLocaleDateString("tr-TR") +
            " " +
            new Date().getHours() +
            ":" +
            new Date().getMinutes(),
        };
        this.notes = [newNote, ...this.notes];
        this.newNote = "";
        this.noteModalShow = false;
      },
      openDeleteHandler(key) {
        this.$bvModal.show("modal-deleteOperation");
        let index = this.notes.indexOf(key);
        this.notes.splice(index, 1);
      },
    },
  
    created() {
      this.$watch((this.albumEdit = this.$route.params.albumid ? true : false));
    },
    watch: {
      $route(to, from) {
        this.albumEdit = this.$route.params.albumid ? true : false;
      },
      tabActive: function () {
        if (this.$route.params.albumid) {
          const currentParams = this.$router.currentRoute.params;
          this.$router.push(`/properties/find/detail/${currentParams.id}`);
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .card {
    width: 100%;
  }
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
  
        border-radius: 50%;
        padding: 0 !important;
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
    span {
      font-size: 1rem;
    }
  }
  .title-card {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .map {
    height: 200px;
  }
  </style>
  